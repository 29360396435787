import { BusinessInfo } from '@wix/bookings-uou-types/dist/src';
import {
  LocationType,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';

export interface ContactSectionViewModel {
  phone?: string;
  email?: string;
  address?: string;
  contactInfo?: ContactInfoViewModel[];
}

export interface ContactInfoViewModel {
  name?: string;
  phone?: string;
  email?: string;
  address?: string;
}

export const contactSectionViewModelFactory = ({
  businessInfo,
  service,
}: {
  businessInfo?: BusinessInfo;
  service?: Service;
}): ContactSectionViewModel => {
  const locations = service?.locations?.filter(
    (location) => location.type === LocationType.BUSINESS && location.business,
  );

  if (locations?.length) {
    const contactInfo = locations.map((location) => {
      const businessLocation = location.business;
      return {
        name: businessLocation?.name,
        // TODO: make sure to add phone and email to the businessLocation
        // @ts-expect-error
        phone: businessLocation?.phone,
        // @ts-expect-error
        email: businessLocation?.email,
        address: businessLocation?.address?.formattedAddress!,
      };
    });
    return { contactInfo };
  }
  return {
    phone: businessInfo?.phone,
    email: businessInfo?.email,
    address: businessInfo?.address,
  };
};
